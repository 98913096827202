<template>
  <b-container fluid>
      <b-row>
        <b-col lg="12" sm="12">
          <iq-card>
            <template v-slot:body>
              <b-overlay>
                <b-row>
                  <b-col lg="12" sm="12" v-if="formData">
                    <div>
                      <p>{{ $t('portal.sd_1') }}</p>
                      <p style="margin: 0px;padding: 0px;">{{ $t('portal.sd_2') }}</p>
                      <p style="margin: 0px;padding: 0px;">{{ $t('portal.sd_3') }}</p>
                      <p>{{ $t('portal.sd_4') }}</p>
                      <p>{{ $t('portal.acc_rqst') }}</p>
                    </div>
                    <b-row>
                      <div class="w-100 text-left">
                        <div class="card-inside-title p-1">
                          <h5 class="text-capitalize text-left p-2"
                            style="color: white!important;font-size: 18px!important; background-color:#02813e!important;"> {{ $t('portal.applicant_information') }}</h5>
                        </div>
                      </div>
                    </b-row>
                    <div class="mt-2">
                      <p style="margin: 2px;padding: 2px;">{{ $t('portal.applicant_type') + ' : ' + getProfessionType(formData.p_profession_type) }}</p>
                      <p style="margin: 2px;padding: 2px;">{{ $t('portal.applicant_name') + ' : ' + ($i18n.locale === 'bn' ? formData.applicant_name_bn : formData.applicant_name) }}</p>
                      <p style="margin: 2px;padding: 2px;">{{ $t('globalTrans.mobile') + ' : ' + ($i18n.locale === 'bn' ? '০' : '0') + $n(formData.mobile, { useGrouping: false }) }}</p>
                      <p style="margin: 2px;padding: 2px;">{{ $t('globalTrans.email') + ' : ' + formData.email }}</p>
                      <p style="margin: 2px;padding: 2px;">{{ $t('portal.name_institution') + ' : ' + ($i18n.locale === 'bn' ? formData.institution_name_bn : formData.institution_name) }}</p>
                      <p style="margin: 2px;padding: 2px;">{{ $t('portal.applicant_designation') + ' : ' + ($i18n.locale === 'bn' ? formData.applicant_designation_bn : formData.applicant_designation) }}</p>
                      <p style="margin: 2px;padding: 2px;">{{ $t('portal.address_individuals_institution') + ' : ' + ($i18n.locale === 'bn' ? formData.address_bn : formData.address) }}</p>
                    </div>
                    <b-row>
                      <div class="w-100 text-left">
                        <div class="card-inside-title p-1">
                          <h5 class="text-capitalize text-left bg-light p-2 badge-light"
                          style="color: white!important;font-size: 18px!important; background-color:#02813e!important;"> {{ $t('portal.room_use_info') }}</h5>
                        </div>
                      </div>
                    </b-row>
                    <div class="mt-2">
                              <p style="margin: 2px;padding: 2px;">{{ $t('portal.use_purpose') + ' : ' + getUsePurpose(formData.use_purpose) }}</p>
                      <p style="margin: 2px;padding: 2px;">{{ $t('globalTrans.title') + ' : ' + ($i18n.locale === 'bn' ? formData.training_title_bn : formData.training_title) }}</p>
                      <p style="margin: 2px;padding: 2px;"><slot>{{ $t('elearning_accommodation.check_in') + ' : ' }}</slot> {{ formData.start_date | dateFormat }}</p>
                      <p style="margin: 2px;padding: 2px;"><slot>{{ $t('elearning_accommodation.check_out') + ' : ' }}</slot> {{ formData.end_date | dateFormat }}</p>
                    </div>
                    <b-row>
                      <div class="w-100 text-left">
                        <div class="card-inside-title p-1">
                          <h5 class="text-capitalize text-left bg-light p-2 badge-light"
                          style="color: white!important;font-size: 18px!important; background-color:#02813e!important;"> {{ $t('portal.user_information') }}</h5>
                        </div>
                      </div>
                    </b-row>
                    <b-table-simple bordered v-if="formData.p_profession_type === 1">
                      <b-tr>
                          <b-th class="text-right" style="width:20%">{{ $t('portal.grade_from') }}</b-th>
                          <b-th class="text-right" style="width:20%">{{ $t('portal.grade_to') }}</b-th>
                          <b-th class="text-right" style="width:20%">{{ $t('portal.male_trainee') }}</b-th>
                          <b-th class="text-right" style="width:20%">{{ $t('portal.female_trainee') }}</b-th>
                          <b-th class="text-right" style="width:20%">{{ $t('portal.total_trainee') }}</b-th>
                      </b-tr>
                      <b-tr v-for="(item,index) in formData.user_details" :key="index">
                          <b-td class="text-right">{{ getGradeForm(item.grade_from) }}</b-td>
                          <b-td class="text-right">{{ getGradeTo(item.grade_to) }}</b-td>
                          <b-td class="text-right">{{ $n(item.male_trainee) }}</b-td>
                          <b-td class="text-right">{{ $n(item.female_trainee) }}</b-td>
                          <b-td class="text-right">{{ $n(item.total_trainee) }}</b-td>
                      </b-tr>
                    </b-table-simple>
                    <b-table-simple bordered v-if="formData.p_profession_type === 2 || formData.p_profession_type === 3">
                      <b-tr>
                          <b-th class="text-right" style="width:20%">{{ $t('portal.officer_type') }}</b-th>
                          <b-th class="text-right" style="width:20%">{{ $t('portal.male_trainee') }}</b-th>
                          <b-th class="text-right" style="width:20%">{{ $t('portal.female_trainee') }}</b-th>
                          <b-th class="text-right" style="width:20%">{{ $t('portal.total_trainee') }}</b-th>
                      </b-tr>
                      <b-tr v-for="(item,index) in formData.user_details" :key="index">
                          <b-td class="text-right">{{ getOfficerType(item.officer_type) }}</b-td>
                          <b-td class="text-right">{{ $n(item.male_trainee) }}</b-td>
                          <b-td class="text-right">{{ $n(item.female_trainee) }}</b-td>
                          <b-td class="text-right">{{ $n(item.total_trainee) }}</b-td>
                      </b-tr>
                    </b-table-simple>
                            <b-row>
                              <div class="w-100 text-left">
                                <div class="card-inside-title p-1">
                                  <h5 class="text-capitalize text-left bg-light p-2 badge-light"
                                  style="color: white!important;font-size: 18px!important; background-color:#02813e!important;"> {{ $t('portal.room_information') }}</h5>
                                </div>
                              </div>
                            </b-row>
                            <b-table-simple bordered>
                              <b-tr>
                                  <b-th class="text-right" style="width:15%">{{ $t('portal.grade_from') }}</b-th>
                                  <b-th class="text-right" style="width:15%">{{ $t('portal.grade_to') }}</b-th>
                                  <b-th class="text-right" style="width:15%">{{ $t('portal.room_type') }}</b-th>
                                  <b-th class="text-right" style="width:15%">{{ $t('elearning_config.room_number') }}</b-th>
                                  <b-th class="text-right" style="width:20%">{{ $t('elearning_config.room_rent') }}</b-th>
                                  <b-th class="text-right" style="width:15%">{{ $t('elearning_config.total_rent') }}</b-th>
                              </b-tr>
                              <b-tr v-for="(item,index) in formData.room_details" :key="index">
                                  <b-td class="text-right">{{ getGradeForm(item.grade_from) }}</b-td>
                                  <b-td class="text-right">{{ getGradeTo(item.grade_to) }}</b-td>
                                  <b-td class="text-right">{{ getRoomType(item.room_type_id) }}</b-td>
                                  <b-td class="text-right">{{ $n(item.room_number) }}</b-td>
                                  <b-td class="text-right">{{ $n(item.room_rent) }}</b-td>
                                  <b-td class="text-right">{{ $n(item.total_rent) }}</b-td>
                              </b-tr>
                            </b-table-simple>
                    <b-row>
                      <b-col md="4"></b-col>
                      <b-col md="4"></b-col>
                      <b-col md="4">
                        <p style="margin-bottom: 0px;padding-bottom: 0px; padding-top: 30px;" class="text-center">{{ $t('portal.sd_6') }}</p>
                        <p style="margin: 0px;padding: 0px;" class="text-center">{{ $i18n.locale === 'bn' ? formData.applicant_name_bn : formData.applicant_name }}</p>
                        <p style="margin: 0px;padding: 0px;" class="text-center">{{ $i18n.locale === 'bn' ? formData.applicant_designation_bn : formData.applicant_designation }}</p>
                        <p style="margin: 0px;padding: 0px;" class="text-center">{{ $i18n.locale === 'bn' ? formData.address_bn : formData.address }}</p>
                      </b-col>
                    </b-row>
                    <!-- <div class="row">
                    <div class="col-sm-6 text-right" v-if="formData.status === 1">
                      <b-button type="submit" variant="danger" class="mr-2" @click="approveReject(item.id, 2)">{{ $t('globalTrans.reject') }}</b-button>
                    </div>
                    <div class="col-sm-6 text-left">
                      <b-button type="submit" variant="primary" class="mr-2" @click="approveReject(item.id, 2)">{{ $t('globalTrans.approve') }}</b-button>
                    </div>
                    </div> -->
                  </b-col>
                </b-row>
              </b-overlay>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-container>
</template>
<script>
import ExportPdf from './export_pdf_details'
import { trainingElearningServiceBaseUrl } from '@/config/api_config'
export default {
  name: 'FormLayout',
  props: ['item'],
  created () {
    this.formData = this.item
  },
  data () {
    return {
      formData: {},
      slOffset: 1
    }
  },
  computed: {
    usePurposeList: function () {
      return [
        { value: 1, text: this.$i18n.locale === 'en' ? 'Training' : 'প্রশিক্ষণ', text_en: 'Training', text_bn: 'প্রশিক্ষণ' },
        { value: 2, text: this.$i18n.locale === 'en' ? 'Workshop' : 'কর্মশালা', text_en: 'Workshop', text_bn: 'কর্মশালা' },
        { value: 3, text: this.$i18n.locale === 'en' ? 'Seminer' : 'সেমিনার', text_en: 'Seminer', text_bn: 'সেমিনার' },
        { value: 4, text: this.$i18n.locale === 'en' ? 'Meeting' : 'মিটিং', text_en: 'Meeting', text_bn: 'মিটিং' },
        { value: 4, text: this.$i18n.locale === 'en' ? 'Personal' : 'ব্যক্তিগত', text_en: 'Personal', text_bn: 'ব্যক্তিগত' }
      ]
    },
    professionTypeList () {
      const professionTypeList = [
        { value: 1, text: this.$i18n.locale === 'en' ? 'Government Institutions' : 'সরকারি প্রতিষ্ঠান', text_en: 'Government Institutions', text_bn: 'সরকারি প্রতিষ্ঠান' },
        { value: 2, text: this.$i18n.locale === 'en' ? 'Private Institutions' : 'বেসরকারি প্রতিষ্ঠান', text_en: 'Private Institutions', text_bn: 'বেসরকারি প্রতিষ্ঠান' },
        { value: 3, text: this.$i18n.locale === 'en' ? 'Person' : 'ব্যক্তি', text_en: 'Person', text_bn: 'ব্যক্তি' }
      ]
      return professionTypeList
    },
    officerTypeList: function () {
      return [
        { value: 1, text: this.$i18n.locale === 'en' ? 'Senior Officer' : 'ঊর্ধ্বতন কর্মকর্তা', text_en: 'Senior Officer', text_bn: 'ঊর্ধ্বতন কর্মকর্তা' },
        { value: 2, text: this.$i18n.locale === 'en' ? 'Mid-level Officer' : 'মিড লেভেল কর্মকর্তা', text_en: 'Mid-level Officer', text_bn: 'মিড লেভেল কর্মকর্তা' },
        { value: 3, text: this.$i18n.locale === 'en' ? 'Junior Officer' : 'জুনিয়র কর্মকর্তা', text_en: 'Junior Officer', text_bn: 'জুনিয়র কর্মকর্তা' }
      ]
    },
    gradeList: function () {
      return this.$store.state.commonObj.gradeList.filter(item => item.status === 0)
    }
  },
  methods: {
    getUsePurpose (id) {
      const trainingType = this.usePurposeList.find(item => item.value === parseInt(id))
      if (this.$i18n.locale === 'bn') {
          return trainingType !== undefined ? trainingType.text_bn : ''
      } else {
          return trainingType !== undefined ? trainingType.text_en : ''
      }
    },
    getProfessionType (id) {
      const trainingType = this.professionTypeList.find(item => item.value === id)
      if (this.$i18n.locale === 'bn') {
          return trainingType !== undefined ? trainingType.text_bn : ''
      } else {
          return trainingType !== undefined ? trainingType.text_en : ''
      }
    },
    getGradeForm (id) {
      const trainingType = this.gradeList.find(item => item.value === id)
      if (this.$i18n.locale === 'bn') {
          return trainingType !== undefined ? trainingType.text_bn : ''
      } else {
          return trainingType !== undefined ? trainingType.text_en : ''
      }
    },
    getGradeTo (id) {
      const trainingType = this.gradeList.find(item => item.value === id)
      if (this.$i18n.locale === 'bn') {
          return trainingType !== undefined ? trainingType.text_bn : ''
      } else {
          return trainingType !== undefined ? trainingType.text_en : ''
      }
    },
    getOfficerType (id) {
      const trainingType = this.officerTypeList.find(item => item.value === id)
      if (this.$i18n.locale === 'bn') {
          return trainingType !== undefined ? trainingType.text_bn : ''
      } else {
          return trainingType !== undefined ? trainingType.text_en : ''
      }
    },
    getRoomType (id) {
      const trainingType = this.$store.state.TrainingElearning.commonObj.roomTypeList.find(item => item.value === id)
        if (this.$i18n.locale === 'bn') {
            return trainingType !== undefined ? trainingType.text_bn : ''
        } else {
            return trainingType !== undefined ? trainingType.text_en : ''
        }
    },
    pdfExport () {
      const reportTitle = this.$t('portal.accommodation_service')
      ExportPdf.exportPdfDetails(trainingElearningServiceBaseUrl, '/config/report-head/detail', 12, reportTitle, this.formData, this)
    }
  }
}
</script>
<style>
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  .report-name{
    font-weight: bold !important;
    text-transform: uppercase;
  }
  .my-btn{
    padding: 2px !important;
  }
</style>
